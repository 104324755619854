import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Adsense from '../../components/Ad';
import { useAdsenseLoader, useAppDispatch, useAppSelector } from '../../hooks';
import PageLayout from '../../components/PageLayout';
import GameContainer from '../../components/GameContainer';
import TopNav from '../../components/TopNav';
import * as styles from './index.module.scss';

const IndexPage = () => {
    useAdsenseLoader();

    return (
        <PageLayout>
            <Helmet>
                <title>Play online drawing games - Skissan</title>
            </Helmet>
            <img src='/skissan.svg' alt='Skissan' />
            <h1>
                <span>Play drawing games with your friends!</span>
            </h1>

            <GameContainer className={styles.instructions}>
                <TopNav active='information' />
                <h2 id='info'>Information</h2>

                <h3>How to play</h3>
                <p>
                    Skissan offers two games. The first game is called{' '}
                    <strong>Draw & guess</strong> which is based on the classic
                    pictionary game. One player draws a word while the others
                    try to guess the drawing. The first player to guess the word
                    will get the most points. The winner will be announced after
                    10 rounds.
                </p>
                <p>
                    The second game is called{' '}
                    <strong>Draw & guess from answers</strong>. Everyone starts
                    by drawing a sentence and then the game will go through
                    every drawing and everyone has to guess what it is. After
                    everyone has made a guess, then they must choose one answer
                    from all the guesses. Only one guess is the correct
                    sentence, but if you click on a guess made by someone else,
                    then that user gets the points! So you can either try to
                    guess what the sentence is, or make a fake answer that
                    people are more likely to choose.
                </p>
                <p>
                    You can <Link to='/create'>create a custom room</Link> if
                    you want to play in private with your friends. After you
                    create a custom room, you can copy-paste the URL to your
                    friends.
                </p>
                <h3>Score system</h3>
                <h4>Draw & guess</h4>
                <p>
                    The drawer will get 10 points when the first user guesses
                    the word. If the drawer gave a hint before the first user
                    guessed the word, then the drawer will get 10 points minus
                    the number of hints given times two. So if the drawer gave
                    two hints, then the drawer will get 6 points when the first
                    user guesses the word. The drawer will then get additional 1
                    point after each correct guess.
                </p>
                <p>
                    The first user to guess the word will get 10 points, the
                    next user who guesses the word will get 9 points, then 8, 7,
                    6 and then 5 points for the rest that successfully guess the
                    word.
                </p>

                <h4>Draw & guess from answers</h4>
                <p>
                    If you guess the correct sentence then you will get 10
                    points and the drawer will also get 10 points minus how many
                    have guessed it.
                </p>
                <p>
                    If someone clicks on your answer, then you will get 10
                    points but the user who clicked on your answer will get
                    none.
                </p>
                <h3>Options</h3>
                <p>
                    When creating a custom room you can configure the following:
                </p>
                <ol>
                    <li>
                        👪 How many players can be in the room, up to 64
                        players.
                    </li>
                    <li>
                        💬 Choose between two word lists, English and Icelandic.
                    </li>
                    <li>💪 Choose how many rounds you want, up to 50.</li>
                    <li>🕓 Choose drawing time, up to 16 minutes.</li>
                    <li>
                        😍 Choose how many words the drawer can choose from.
                    </li>
                    <li>
                        🎯 Limit how many times players can guess in a single
                        round.
                    </li>
                    <li>
                        🕵️ Setting the room to private will hide it from the
                        lobby.
                    </li>
                </ol>
                <h3>Rules</h3>
                <ol>
                    <li>⛔ You are not allowed to draw letters.</li>
                    <li>⛔ You are not allowed to draw offensive art.</li>
                    <li>
                        🤐 If you guessed the word, do not give away the answer
                        to others.
                    </li>
                    <li>
                        ⛔ You are not allowed to share personal details such as
                        your email address or phone number.
                    </li>
                </ol>
                <h3>Community</h3>
                <a
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    target='_blank'
                    href='https://discord.gg/tfYzagJ8tG'
                >
                    Join our Discord community!{' '}
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='31'
                        height='15'
                        viewBox='0 0 71 55'
                        fill='none'
                    >
                        <g clipPath='url(#clip0)'>
                            <path
                                d='M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z'
                                fill='#5865F2'
                            />
                        </g>
                        <defs>
                            <clipPath id='clip0'>
                                <rect width='71' height='55' fill='white' />
                            </clipPath>
                        </defs>
                    </svg>
                </a>

                <form
                    action='https://www.paypal.com/donate'
                    method='post'
                    target='_top'
                    style={{ marginTop: '2rem' }}
                >
                    <input
                        type='hidden'
                        name='hosted_button_id'
                        value='77SXPLQQQ2SEU'
                    />
                    <input
                        type='image'
                        src='https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif'
                        border='0'
                        name='submit'
                        title='PayPal - The safer, easier way to pay online!'
                        alt='Donate with PayPal button'
                    />
                    <img
                        alt=''
                        border='0'
                        src='https://www.paypal.com/en_IS/i/scr/pixel.gif'
                        width='1'
                        height='1'
                    />
                </form>
            </GameContainer>
            <Adsense responsive adFormat='horizontal' />
            <GameContainer className={styles.instructions}>
                <h2 id='faq'>Frequently Asked Questions</h2>
                <h4>Can I add my own word list to Skissan?</h4>
                <p>
                    Absolutely! When you create a custom room you can submit
                    your own custom words.
                </p>
                <h4>Can I create an account?</h4>
                <p>
                    We support signing in with Discord, which will save your
                    avatar and total score. We will soon offer rooms where only
                    players with certain amount of score can enter.
                </p>
                <h4>Are there more game modes available?</h4>
                <p>
                    We only support one game mode. If you have some interesting
                    ideas then please feel free to{' '}
                    <a target='_blank' href='https://slidesome.com/contact'>
                        contact us
                    </a>{' '}
                    and we will look into adding it. Please note that you can
                    specify maximum number of guesses and drawing time in custom
                    rooms.
                </p>
                <h4>Can I play on my mobile or tablet?</h4>
                <p>
                    Yes, Skissan was created to run on any screen, even in cars
                    such as Tesla!
                </p>
                <h4>I'm having trouble playing Skissa, what can I do?</h4>
                <p>
                    Please make sure that you are using a recent browser such as
                    Google Chrome or FireFox. If you are still having trouble
                    playing, then please join our{' '}
                    <a target='_blank' href='https://discord.gg/tfYzagJ8tG'>
                        Discord community
                    </a>{' '}
                    and ask for help.
                </p>
                <h4>Where can I share feedback or chat with other players?</h4>
                <p>
                    We have a{' '}
                    <a target='_blank' href='https://discord.gg/tfYzagJ8tG'>
                        Discord community
                    </a>
                    , where you can share feedback, chat with other players and
                    more.
                </p>
            </GameContainer>
            <GameContainer>
                <h3>News</h3>
                <h4>8th of January 2022</h4>
                <p>
                    Skissan is no longer only a draw and guess game, because we
                    now support two game modes, classic draw & guess and a brand
                    new game mode called{' '}
                    <strong>Draw & guess from answers</strong>.{' '}
                </p>
                <p>
                    This new game mode is really fun. Everyone starts by drawing
                    a sentence and then the game will go through every drawing
                    and everyone has to guess what it is. After everyone has
                    made a guess, then they must choose one guess from all the
                    answers. Only one answer is the correct sentence, but if you
                    click on an answer made by someone else, then he gets
                    points! So you can either try to guess what the sentence
                    really is, or make a fake answer that people are more likely
                    to choose.
                </p>
            </GameContainer>
        </PageLayout>
    );
};

export default IndexPage;
